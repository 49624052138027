import VCalendar from './VCalendar'
import VCalendarDaily from './VCalendarDaily'
import VCalendarWeekly from './VCalendarWeekly'
import VCalendarMonthly from './VCalendarMonthly'

export { VCalendar, VCalendarDaily, VCalendarWeekly, VCalendarMonthly }

export default {
  $_vuetify_subcomponents: {
    VCalendar,
    VCalendarDaily,
    VCalendarWeekly,
    VCalendarMonthly,
  },
}
