export default {
  badge: 'Skilt',
  close: 'Lukk',
  dataIterator: {
    noResultsText: 'Fant ingen matchende elementer.',
    loadingText: 'Laster elementer...',
  },
  dataTable: {
    itemsPerPageText: 'Rader per side:',
    ariaLabel: {
      sortDescending: 'Sortert synkende.',
      sortAscending: 'Sortert stigende.',
      sortNone: 'Ikke sortert.',
      activateNone: 'Aktiver for å fjerne sortering.',
      activateDescending: 'Aktiver for å sortere synkende.',
      activateAscending: 'Aktiver for å sortere stigende.',
    },
    sortBy: 'Sorter etter',
  },
  dataFooter: {
    itemsPerPageText: 'Elementer per side:',
    itemsPerPageAll: 'Alle',
    nextPage: 'Neste side',
    prevPage: 'Forrige side',
    firstPage: 'Første side',
    lastPage: 'Siste side',
    pageText: '{0}-{1} av {2}',
  },
  datePicker: {
    itemsSelected: '{0} valgt',
  },
  noDataText: 'Ingen data er tilgjengelig',
  carousel: {
    prev: 'Forrige bilde',
    next: 'Neste bilde',
    ariaLabel: {
      delimiter: 'Karusellbilde {0} av {1}',
    },
  },
  calendar: {
    moreEvents: '{0} flere',
  },
  fileInput: {
    counter: '{0} filer',
    counterSize: '{0} filer ({1} totalt)',
  },
  timePicker: {
    am: 'AM',
    pm: 'PM',
  },
}
